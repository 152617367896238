<template>
  <div id="positionAdress">
    <span>{{position_adress_data || '定位中'}}</span>
  </div>
</template>
<script>
export default {
  data() {
    return {
      position_adress_data:''
    };
  },
  created() {
    this.getLocation();
  },
  methods: {
    // 首页地区定位 切记！！！！！！ 如果不是https协议则无法进行高德gps定位，只能采取备选方案2 ip定位
    getLocation() {
      const self = this;
      AMap.plugin("AMap.Geolocation", () => {
        var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
        });

        geolocation.getCurrentPosition();
        AMap.event.addListener(geolocation, "complete", onComplete);
        AMap.event.addListener(geolocation, "error", onError);

        function onComplete(data) {
          // data是具体的定位信息
          console.log("定位成功信息：", data);
          let reg = /.+?(省|市|自治区|自治州|县|区)/g;
          // self.userPosition = data.formattedAddress.match(reg)[0].slice(0,-1)
          self.userPosition = data.formattedAddress.match(reg)[0];
          // self.userPosition = data.formattedAddress;
          console.log(self.userPosition);
          // this.$emit("positionAdress", self.userPosition);
          self.position_adress_data = self.userPosition
        }

        function onError(data) {
          // 定位出错
          console.log("定位失败错误：", data);
          // 调用ip定位
          self.getLngLatLocation();
        }
      });
    },
    getLngLatLocation() {
      const self = this;
      AMap.plugin("AMap.CitySearch", function () {
        var citySearch = new AMap.CitySearch();
        citySearch.getLocalCity(function (status, result) {
          if (status === "complete" && result.info === "OK") {
            // 查询成功，result即为当前所在城市信息
            console.log("通过ip获取当前城市：", result);
            self.userPosition = result.city;
            //逆向地理编码
            AMap.plugin("AMap.Geocoder", function () {
              var geocoder = new AMap.Geocoder({
                // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
                city: result.adcode,
              });

              var lnglat = result.rectangle.split(";")[0].split(",");
              geocoder.getAddress(lnglat, function (status, data) {
                if (status === "complete" && data.info === "OK") {
                  // result为对应的地理位置详细信息
                  console.log(data);
                }
              });
            });
          }
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
</style>