<template>
  <div id="uploadDate">
    <Loading :show="true" title="上传中" v-show="!uploadDateReady || !uploadDateVReady" />
    <p class="uploadDateTitle">
      <slot name="uploadTiele">
      </slot>
      <span>
        <slot name="uploadTiele1">
        </slot>
      </span>
    </p>
    <div class="van-hairline--bottom"></div>
    <div class="selectUpload">
      <div class="ListLeft">
        <van-uploader max-count="6" :after-read="afterRead" multiple>
          <svg class="icon icon-reset-upload" aria-hidden="true">
            <use xlink:href="#icon-upload"></use>
          </svg>
        </van-uploader>
        <p class="typeTitle">上传照片</p>
      </div>
      <div class="ListRight">
        <div class="imgItem" v-for="(item,index) in uploadImageList" :key="index" :style="`background: url('${cdnHttp}${item}?imageView2/1/format/webp/w/110/h/110/q/90') no-repeat center / cover`" @click="imagePreview(index)">
        </div>
      </div>
    </div>
    <div class="selectUpload">
      <div class="ListLeft">
        <van-uploader max-count="1" :after-read="afterReadRadio" accept="video/*">
          <svg class="icon icon-reset-upload" aria-hidden="true">
            <use xlink:href="#icon-upload"></use>
          </svg>
        </van-uploader>
        <p class="typeTitle">上传视频</p>
      </div>
      <div class="ListRight" v-if="uploadDateVReady">
        {{tipMessage}}
      </div>
    </div>
  </div>
</template>
<script>
import { ImagePreview } from "vant";
import Loading from "@/components/public/animation/Loading";
export default {
  props: {},
  data() {
    return {
      fileList: [],
      uploadImageList: [],
      videoId: "",
      uploadDateReady: true,
      uploadDateVReady: true,
      tipMessage: "",
    };
  },
  components: {
    Loading,
  },
  created() {
    console.log(this.upLoadInfo);
    // console.log(this.$route);
    // console.log(this.cdnHttp);
  },
  methods: {
    imagePreview(index) {
      let _imgList = [];
      this.uploadImageList.forEach((item) => {
        _imgList.push(`${this.cdnHttp}${item}`);
      });
      ImagePreview({ images: _imgList, startPosition: index });
    },
    afterRead(file, type) {
      this.uploadDateReady = false;
      let imageFormData = new FormData();
      console.log(file);
      if (file.length > 1) {
        for (let i = 0; i < file.length; i++) {
          imageFormData.append(`files[${i}]`, file[i].file);
        }
      } else {
        imageFormData.append(`files[0]`, file.file);
      }
      imageFormData.append("type", 1);
      imageFormData.append("order_id", this.$route.params.orderId);
      this.$http
        .post("/api/worker/uploadInspection", imageFormData, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          // console.log(res, "ssss");
          // console.log(res.data.result);
          // console.log(res.data.result.url);
          res.data.result.forEach((item, index) => {
            this.uploadImageList.push(item.url);
          });
          this.$emit("getImageList", this.uploadImageList);
          this.uploadDateReady = true;
        });
    },
    afterReadRadio(file) {
      this.uploadDateVReady = false;
      let videoFormDate = new FormData();
      console.log(file);
      videoFormDate.append(`files[0]`, file.file);
      videoFormDate.append("type", 2);
      videoFormDate.append("order_id", this.$route.params.orderId);
      this.$http
        .post("/api/worker/uploadInspection", videoFormDate, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: `bearer ${window.localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          console.log(res.data.result[0], "ssss");
          this.$emit("getVideoId", res.data.result[0].url);
          this.uploadDateVReady = true;
          this.tipMessage = "视频上传成功";
        })
        .catch((err) => {
          // console.log(err);
          this.videoId = err;
        });
    },
  },
};
</script>

<style lang="less" >
#uploadDate {
  .icon-reset-upload {
    width: 3rem;
    height: 3rem;
  }
  width: 100%;
  background: #fff;
  box-sizing: border-box;
  .uploadDateTitle {
    font-size: 1.4rem;
    padding: 1.2rem 0;
    text-align: center;
    span {
      font-size: 1.4rem;
      color: #b0b0b0;
      padding-left: 0.85rem;
    }
  }
  .selectUpload {
    padding: 1rem;
    display: flex;
    align-items: center;
    .ListLeft {
      padding-right: 1rem;
      width: 7rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .ListRight {
      flex: 1;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: scroll;
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* IE 10+ */
      .imgItem {
        flex: 0 0 auto;
        width: 9rem;
        height: 9rem;
        margin-right: 0.4rem;
        border-radius: 4px;
      }
    }
    .ListRight::-webkit-scrollbar {
      width: 0px;
    }
    .typeTitle {
      font-size: 1.4rem;
      padding: 0.5rem 0;
    }
  }
}
</style>