<template>
  <div id="selectResult">
    <p class="resultTitle">{{theme.title}}<span>{{theme.viceTitle}}</span></p>
    <div class="van-hairline--bottom"></div>
    <div class="reservationResult">
      <div :class="actTemp == index? 'resultSelect act': 'resultSelect'" @click="selectStatus(item,index)" v-for="(item,index) in selectStatusList" :key="index">
        <span> {{item.status[0]}} </span>
        <span class="des"> {{item.status[1]}} </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    selectStatusList: {
      type: Array,
      default() {
        return [];
      },
    },
    theme: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      actTemp: -1,
    };
  },
  methods: {
    selectStatus(item, index) {
      let showType = {};
      if (index == 0) {
        showType.successShow = true;
        showType.failShow = false;
        this.$emit("getshowType", showType);
      } else {
        showType.successShow = false;
        showType.failShow = true;
        this.$emit("getshowType", showType);
      }
      this.actTemp = index;
      let selectResult = {};
      selectResult["contact_status"] = item.contact_status;
      selectResult["status"] = item.status;
      this.$emit("getSelectResult", selectResult);
    },
  },
};
</script>
<style lang="less" scoped>
#selectResult {
  margin: 1.8rem 0;
  background: #fff;
  text-align: center;
  .resultTitle {
    font-size: 1.6rem;
    padding: 1.2rem 0;
    span {
      font-size: 1.4rem;
      color: #b0b0b0;
      padding-left: 0.85rem;
    }
  }
  .reservationResult {
    width: 100%;
    height: 10rem;
    background: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .resultSelect {
      padding: 0.5rem 0;
      width: 40%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #ccc;
      font-size: 1.6rem;
      border-radius: 6px;
      color: #c2c2c2;
      flex-direction: column;
      span {
        display: block;
        padding: 0.4rem 0;
        &.des {
          font-size: 1.4rem;
        }
      }
      &.act {
        color: @themeColor;
        border: 1px solid @themeColor;
        font-weight: 600;
        position: relative;
        overflow: hidden;
      }
    }
    .act:after {
      content: "";
      display: block;
      width: 2rem;
      height: 2rem;
      border-radius: 50px;
      position: absolute;
      right: 2px;
      top: 0;
    }
  }
}
</style>