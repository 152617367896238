<template>
  <div id="inspection">
    <van-nav-bar title="拆包验货" left-arrow @click-left="goLastPage" :border="false" />
    <div class="filpWrap">
      <p class="filpTitle">已上门签到</p>
      <p class="signAderess">
        <span>
          <svg t="1603950255592" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4639" width="22" height="22">
            <path d="M516.320553 65.913723C327.473365 66.759049 174.512778 221.085162 175.350878 409.954025c0.787526 177.21493 282.974557 496.596289 315.154731 532.417865a39.737529 39.737529 0 0 0 59.440119-0.2601c31.862273-36.110576 311.202653-357.991787 310.415127-535.213942-0.838101-188.861638-155.178664-341.822225-344.040302-340.984125z m2.196402 492.860961c-76.483906 0.339575-139.009102-61.614845-139.348677-138.105976-0.339575-76.491131 61.629295-139.001877 138.105976-139.334227 76.512806-0.3468 139.023552 61.60762 139.363127 138.091526 0.339575 76.498356-61.614845 139.001877-138.120426 139.348677z" fill="#FF816C" p-id="4640"></path>
          </svg>
        </span>
        <span>{{signInInfo.address}}</span>
      </p>
      <p class="serviceTime">{{signInInfo.time}}</p>
    </div>
    <SelectResult :selectStatusList="selectStatusList" @getSelectResult="getSelectResult" :theme="theme" />
    <div style="margin-bottom:1.8rem">
      <van-field v-show="selectResult.contact_status == 2? true:false" v-model="message" rows="2" autosize label="异常描述" type="textarea" maxlength="50" placeholder="请描述验货异常的原因，并及时联系下单方，避免不必要纠纷" show-word-limit />
    </div>
    <UploadInspection @image="getFormData" v-show="selectResult.contact_status > 0? true:false" @getImageList="getImageList" @getVideoId="getVideoId">
      <template #uploadTiele>
        验货商品照片
      </template>
      <template #uploadTiele1>
        (至少上传2张)
      </template>
    </UploadInspection>
    <div class="positionBottom">
      <van-button color="linear-gradient(to right, #ff6034, #ee0a24)" block @click="submitFormData">
        下一步
      </van-button>
    </div>
  </div>
</template>
<script>
import TopBar from "@/components/home/TopBar.vue";
import TimeFilpDown from "@/components/effect/TimeFilpDown.vue";
import PositionAdress from "@/components/effect/PositionAdress.vue";
import SelectResult from "@/components/effect/SelectResult.vue";
import UploadInspection from "@/components/effect/UploadInspection.vue";

export default {
  data() {
    return {
      FormData: {},
      signInInfo: {},
      selectStatusList: [
        {
          status: ["验货正常", "商品完好"],
          contact_status: 1,
        },
        {
          status: ["验货异常", "商品破损或缺少配件"],
          contact_status: 2,
        },
      ],
      theme: {
        title: "商品验收结果",
        viceTitle: "(请与客户一起验收)",
      },
      selectResult: {},
      videoId: "",
      uploadImageList: [],
      message: "",
    };
  },
  components: {
    TimeFilpDown,
    TopBar,
    PositionAdress,
    SelectResult,
    UploadInspection,
  },
  created() {
    this.getSignInInfo();
    this.getNowTime();
  },
  methods: {
    getSignInInfo() {
      this.$http
        .get(`/api/v2/worker/order/${this.$route.params.orderId}/clock_in`)
        .then((res) => {
          console.log("获取签到的信息", res);
          this.signInInfo = res.data.order.last_clock_in;
        });
    },
    getVideoId(e) {
      this.videoId = e;
    },
    getImageList(e) {
      this.uploadImageList = e;
    },
    getFormData(e) {
      this.FormData = e;
    },
    getSelectResult(e) {
      this.selectResult = e;
      console.log(this.selectResult);
    },
    getNowTime() {
      let dd = new Date();
      setTimeout(this.getNowTime, 1000);
      this.signinTime = dd.toLocaleString();
    },
    submitFormData() {
      // console.log(this.uploadImageList.join(","));
      switch (this.selectResult.contact_status) {
        case 1:
          if (this.uploadImageList.length < 2) {
            this.$toast("上传照片至少2张");
          } else {
            this.$http
              .post(
                `/api/v2/worker/order/${this.$route.params.orderId}/inspection`,
                {
                  status: this.selectResult.contact_status,
                  images_url: this.uploadImageList.join(","),
                  video: this.videoId,
                }
              )
              .then((res) => {
                if (res.data.code == 0) {
                  this.$toast("验收结果提交成功");
                  this.goSubPageR("order");
                }
              })
              .catch((err) => {
                console.log(err);
                this.$toast(err.error);
                if (err.code == 1001) {
                  this.goSubPage("order");
                }
              });
          }
          break;
        case 2:
          if (this.message.length <= 0) {
            this.$toast("请填写货物异常原因");
          } else if (this.uploadImageList.length < 2) {
            this.$toast("上传照片至少2张");
          } else {
            this.$http
              .post(
                `/api/v2/worker/order/${this.$route.params.orderId}/inspection`,
                {
                  status: this.selectResult.contact_status,
                  reason: this.message,
                  images_url: this.uploadImageList.join(","),
                  video: this.videoId,
                }
              )
              .then((res) => {
                this.$toast("提交成功");
                this.goSubPage("order");
              })
              .catch((err) => {
                switch (err.code) {
                  case 1003:
                    this.$toast("请填写货物异常原因");
                    break;
                  case 1002:
                    this.$toast("请填写商品验收结果");
                    break;
                  case 1004:
                    this.$toast("上传照片至少2张");
                    break;
                  case 1005:
                    this.$toast("您已验过货物");
                    this.goSubPageR("order");
                    break;
                }
              });
          }
          break;
        default:
          this.$toast("请先选择商品验收结果");
      }
    },
  },
};
</script>
<style lang="less" scoped>
#inspection {
  padding-bottom: 8rem;
  .filpWrap {
    padding: 1rem 0;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 93%;
    margin: 2rem auto;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 #eaeaea;
    p {
      padding: 1rem 0;
      &.filpTitle {
        font-weight: 600;
        font-size: 2rem;
        text-align: center;
        color: #232323;
      }
      &.serviceTime {
        font-size: 1.4rem;
        color: #b0b0b0;
      }
      &.signAderess {
        font-size: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 1.5rem;
        box-sizing: border-box;
      }
    }
  }
  .positionWrap {
    width: 93%;
    margin: 2rem auto 2rem;
    background: #fff;
    padding: 1rem 1.5rem;
    box-sizing: border-box;
    border: 2px solid #ddd;
    border-radius: 10px;
    border-image: -webkit-linear-gradient(orange, #ff816c) 30 30;
    border-image: -moz-linear-gradient(orange, #ff816c) 30 30;
    border-image: linear-gradient(orange, #ff816c) 30 30;
    .row {
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      padding: 1rem 0;
      .publicFont {
        font-size: 1.4rem;
        text-indent: 1rem;
      }
    }
  }
  .positionBottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .insurance {
    width: 100%;
    height: 5rem;
    background: #fff;
    font-size: 1.6rem;
    text-align: center;
    line-height: 5rem;
  }
}
</style>